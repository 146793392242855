var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "centered", staticStyle: { width: "100%", height: "100%" } },
    [
      !_vm.isReady
        ? _c("div", { staticClass: "brand-medium font-24" }, [
            _vm._v(_vm._s(_vm.$t("invoices.payment.loading"))),
          ])
        : _c(
            "v-container",
            {
              staticClass: "pa-12",
              staticStyle: { "max-width": "800px" },
              attrs: { fluid: "" },
            },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center pt-10 pb-8",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          src: _vm.logo,
                          "max-height": "100",
                          contain: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12", align: "center" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "column-format",
                        staticStyle: { "max-width": "fit-content" },
                      },
                      [
                        _c("div", { staticClass: "brand-medium font-24" }, [
                          _vm._v(_vm._s(_vm.$t("invoices.payment.options"))),
                        ]),
                        _c("div", { staticClass: "column-format gap-1 py-5" }, [
                          _c(
                            "div",
                            {
                              staticClass: "row-format",
                              staticStyle: {
                                "border-bottom":
                                  "1px solid var(--v-gray_30-base)",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("invoices.list.invoice-number")
                                  ) + ":"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ml-auto font-weight-bold" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.paymentInfo.invoiceNumberFormatted
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "row-format",
                              staticStyle: {
                                "border-bottom":
                                  "1px solid var(--v-gray_30-base)",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("invoice.details.issued")) + ":"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ml-auto font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.DateTime.fromISO(
                                          _vm.paymentInfo.dateSent
                                        ).toLocaleString(_vm.DateTime.DATE_MED)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "row-format",
                              staticStyle: {
                                "border-bottom":
                                  "1px solid var(--v-gray_30-base)",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("invoice.details.due")) + ":"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ml-auto font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.DateTime.fromISO(
                                          _vm.paymentInfo.dateDue
                                        ).toLocaleString(_vm.DateTime.DATE_MED)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "row-format",
                              staticStyle: {
                                "border-bottom":
                                  "1px solid var(--v-gray_30-base)",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("invoice.details.total-due")) +
                                    ":"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ml-auto font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$formatters.dollars(
                                          _vm.paymentInfo.invoiceAmount,
                                          true,
                                          true,
                                          _vm.paymentInfo.currency
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _vm.achEnabled || _vm.paymentMethods.length
                    ? _c(
                        "v-col",
                        {
                          class:
                            _vm.smallScreen || !_vm.cardEnabled
                              ? "border-bottom"
                              : "border-right",
                          attrs: { cols: "12", sm: _vm.cardEnabled ? 6 : 12 },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "column-format",
                              staticStyle: { height: "100%" },
                            },
                            [
                              _c(
                                "h2",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("invoices.payment.ach")) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "material-symbols-outlined pointer",
                                      attrs: { color: "primary", size: "20" },
                                      on: {
                                        click: _vm.editSavedPaymentMethods,
                                      },
                                    },
                                    [_vm._v("edit")]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(_vm.paymentMethods, function (account) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: account.id,
                                    staticClass: "primary-action my-1",
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmPayment(account)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("invoices.payment.pay-with")
                                      ) +
                                        " " +
                                        _vm._s(account.label) +
                                        " - " +
                                        _vm._s(account.last4)
                                    ),
                                  ]
                                )
                              }),
                              _vm.paymentMethods.length === 0
                                ? _c("div", { staticClass: "my-3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "invoices.payment.no-linked-accounts"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.achEnabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "pointer my-1",
                                      on: { click: _vm.initializeACHLink },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [_vm._v("$plus")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "brand-medium",
                                          staticStyle: {
                                            color: "var(--v-primary-base)",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "invoices.payment.new-linked"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "pt-4 mt-auto font-12",
                                  staticStyle: { "font-style": "italic" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mb-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("$stripe")]
                                  ),
                                  _vm._v(
                                    _vm._s(_vm.$t("invoices.payment.stripe")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.cardEnabled
                    ? _c(
                        "v-col",
                        {
                          class:
                            _vm.smallScreen || !_vm.cardEnabled
                              ? "border-bottom"
                              : "",
                          attrs: {
                            cols: "12",
                            sm:
                              _vm.achEnabled || _vm.paymentMethods.length
                                ? 6
                                : 12,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "column-format",
                              staticStyle: { height: "100%" },
                            },
                            [
                              _c("h2", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("invoices.payment.payment-options")
                                  )
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "pointer payment-container my-1",
                                  on: {
                                    click: function ($event) {
                                      return _vm.initiateStripeCheckout()
                                    },
                                  },
                                },
                                [
                                  _c("p", { staticClass: "mt-3" }, [
                                    _c(
                                      "span",
                                      { staticClass: "mr-2" },
                                      [
                                        _c("payment-icon", {
                                          attrs: { size: "16", name: "amex" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "mr-2" },
                                      [
                                        _c("payment-icon", {
                                          attrs: {
                                            size: "16",
                                            name: "master_card",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "mr-2" },
                                      [
                                        _c("payment-icon", {
                                          attrs: { size: "16", name: "visa" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "mr-2" },
                                      [
                                        _c("payment-icon", {
                                          attrs: {
                                            size: "16",
                                            name: "discover",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("p", [
                                    _c(
                                      "span",
                                      { staticClass: "mr-2" },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [_vm._v("$apple")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("invoices.payment.apple-pay")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("p", [
                                    _c(
                                      "span",
                                      { staticClass: "mr-2" },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [_vm._v("$google")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("invoices.payment.google-pay")
                                      ) + " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pt-4 mt-auto font-12",
                                  staticStyle: { "font-style": "italic" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mb-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("$stripe")]
                                  ),
                                  _vm._v(
                                    _vm._s(_vm.$t("invoices.payment.stripe")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", {
                      staticClass: "mt-4",
                      attrs: { id: "paypal-button-container" },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }