var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.customPage
        ? _c("custom-page", { attrs: { slug: _vm.customPage.slug } })
        : _c(
            "div",
            {
              staticClass: "column-format centered",
              style: `height:100%; width:100%; --background:url('${_vm.domain.customBranding.loginSplash}')`,
              attrs: { id: "welcome-panel" },
            },
            [
              _c(
                "div",
                { staticClass: "content-panel column-format centered" },
                [
                  _c("div", { staticStyle: { "max-width": "600px" } }, [
                    _c("div", {
                      staticClass: "mt-8",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.domain.customBranding.welcomeGreeting
                        ),
                      },
                    }),
                  ]),
                  _vm.actionItems
                    ? _c(
                        "div",
                        {
                          staticClass: "row-format mt-8 px-8",
                          staticStyle: { "flex-wrap": "wrap" },
                        },
                        [
                          _vm.features.projectsEnabled
                            ? _c("deliverables", {
                                staticClass: "action-item",
                                attrs: {
                                  deliverables: _vm.actionItems.deliverables,
                                },
                                on: { refresh: _vm.getActionItems },
                              })
                            : _vm._e(),
                          _vm.features.invoicesEnabled
                            ? _c("invoices", {
                                staticClass: "action-item",
                                attrs: { invoices: _vm.actionItems.invoices },
                                on: { refresh: _vm.getActionItems },
                              })
                            : _vm._e(),
                          _vm.features.proposalsEnabled
                            ? _c("proposals", {
                                staticClass: "action-item",
                                attrs: { proposals: _vm.actionItems.proposals },
                                on: { refresh: _vm.getActionItems },
                              })
                            : _vm._e(),
                          _vm.features.meetingsEnabled
                            ? _c("meetings", {
                                staticClass: "action-item",
                                attrs: { meetings: _vm.actionItems.meetings },
                                on: { refresh: _vm.getActionItems },
                              })
                            : _vm._e(),
                          _vm.features.invoicesEnabled
                            ? _c("subscription-requests", {
                                staticClass: "action-item",
                                attrs: {
                                  "subscription-requests":
                                    _vm.actionItems.subscriptionRequests,
                                },
                                on: { refresh: _vm.getActionItems },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }