import { render, staticRenderFns } from "./NewTicket.vue?vue&type=template&id=6977411a&scoped=true"
import script from "./NewTicket.vue?vue&type=script&lang=js"
export * from "./NewTicket.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6977411a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VChip,VCol,VContainer,VFileInput,VRow,VSelect,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1200640372/src/struxture-clientportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6977411a')) {
      api.createRecord('6977411a', component.options)
    } else {
      api.reload('6977411a', component.options)
    }
    module.hot.accept("./NewTicket.vue?vue&type=template&id=6977411a&scoped=true", function () {
      api.rerender('6977411a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/tickets/NewTicket.vue"
export default component.exports