import { render, staticRenderFns } from "./Divider.vue?vue&type=template&id=9a76ca26&scoped=true"
import script from "./Divider.vue?vue&type=script&lang=js"
export * from "./Divider.vue?vue&type=script&lang=js"
import style0 from "./Divider.vue?vue&type=style&index=0&id=9a76ca26&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a76ca26",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3493965360/src/struxture-clientportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9a76ca26')) {
      api.createRecord('9a76ca26', component.options)
    } else {
      api.reload('9a76ca26', component.options)
    }
    module.hot.accept("./Divider.vue?vue&type=template&id=9a76ca26&scoped=true", function () {
      api.rerender('9a76ca26', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/templates/invoices/schema/Divider.vue"
export default component.exports