import { render, staticRenderFns } from "./UserMenu.vue?vue&type=template&id=d9974520"
import script from "./UserMenu.vue?vue&type=script&lang=js"
export * from "./UserMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3493965360/src/struxture-clientportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d9974520')) {
      api.createRecord('d9974520', component.options)
    } else {
      api.reload('d9974520', component.options)
    }
    module.hot.accept("./UserMenu.vue?vue&type=template&id=d9974520", function () {
      api.rerender('d9974520', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/nav/UserMenu.vue"
export default component.exports